import { useEffect, useState } from "react";
//Importa el cliente de supabase
import  SupabaseClient, { supabase }  from "../../supabaseClient";

const PromoComponent = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        // Realiza la consulta a la base de datos
        const { data, error } = await supabase
          .from('servicios')  // Asegúrate de que el nombre de la tabla sea correcto
          .select('*');  // Selecciona todos los campos

        if (error) {
          throw error;  // Lanza un error si hay un problema
        }

        setServices(data);  // Guarda los datos en el estado
      } catch (error) {
        console.error('Error fetching services:', error);
        setError(error.message);  // Guarda el mensaje de error en el estado
      } finally {
        setLoading(false);  // Finaliza la carga
      }
    };

    fetchServices();  // Llama a la función para obtener los servicios
  }, []);  // El array vacío asegura que esto se ejecute una vez cuando se monte el componente

  if (loading) return <p>Cargando servicios...</p>;  // Mensaje de carga
  if (error) return <p>Error: {error}</p>;  // Muestra un mensaje de error
  
  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '0 auto',
      padding: '25px',
      
    },
    card: {
      backgroundColor:'#fff',
      flex: '1 0 30%',
      margin: '10px',
      padding: '25px',
      border: '5px solid #ffff',
      borderRadius: '5px',
      boxShadow: '0 2px 5px grey',
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
  };
  
  return (
    <div style={styles.container}>
      {services.length > 0 ? (
        services.map(service => (
          <div key={service.id} style={styles.card}>
            <h4>{service.name}</h4>  {/* nombres de las columnas*/}
            <p>{service.description}</p>
            <p>Precio unico: {service.precio_unico}</p>
            <p>En dos pagos de: {service.precio_dos_pagos}</p>
            <img src={service.imagen} alt={service.nombre} style={styles.image}/>
          </div>
        ))
      ) : (
        <p>No hay servicios disponibles.</p>
      )}
    </div>
  );
};

export default PromoComponent;
